import React from "react"

import PageWrapper from "../PageWrapper"
import { Container } from "react-bootstrap"
import { Section, Title } from "../Core"
import PostDetails from "../../sections/blog/PostDetails"
import SEO from "../SEO"

import { MDXProvider } from "@mdx-js/react"

const MyH1 = (props) => <Title variant="hero" {...props} />

const components = {
  h1: MyH1,
}

const PageLayout = ({ children, location }) => {
  return (
    <>
      <SEO pathname={location.pathname} />
      <PageWrapper footerDark>
        <Section className="pb-0">
          <Container>
            <PostDetails>
              <MDXProvider components={components}>{children}</MDXProvider>
            </PostDetails>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default PageLayout
